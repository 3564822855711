async function importFlickity() {
    const { default: Flickity } = await import(/* webpackChunkName: "flickity" */ 'flickity');

    return Flickity;
}

importFlickity().then((Flickity) => {

    // find focusable elements in slide
    function getKeyboardFocusableElements (element = document) {
        return [...element.querySelectorAll(
            'a, button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])'
        )]
            .filter(el => !el.hasAttribute('disabled'));
    }

    // call to update carousel dot select states & aria attributes
    function selectCarouselDots (pageDotsGroup, pageDotButtons, heroSlider) {
        let previousSelectedButton = pageDotsGroup.querySelector('.is-selected');
        let selectedButton = pageDotsGroup.children[heroSlider.selectedIndex];
        previousSelectedButton.classList.remove('is-selected');
        previousSelectedButton.setAttribute('aria-selected', 'false');
        selectedButton.classList.add('is-selected');
        selectedButton.setAttribute('aria-selected', 'true');
    }

    // get hidden carousel cells
    function setHeroSlide (hiddenSlides) {
        hiddenSlides.forEach((hiddenSlide) => {
            hiddenSlide.setAttribute('tabindex', '-1');
            // remove focusable elements from tabindex
            // within hidden carousel cells
            let focusEls = getKeyboardFocusableElements(hiddenSlide);
            focusEls.forEach((focusEl => {
                focusEl.setAttribute('tabindex', '-1')
            }));
        });
    }

    const hero = document.querySelector('.featured-slider');
    // const heroTabs = document.querySelectorAll('.hero-nav-item');
    // const asNavForHeroArray = Array.prototype.slice.call(heroTabs);

    // init flickity slider
    const heroSlider = new Flickity(hero, {
        // flickity event binding
        // https://flickity.metafizzy.co/events.html#ready
        on: {
            ready: () => {
                hero.setAttribute('tabindex', '-1');
                let hiddenSlides = document.querySelectorAll(".featured-slide[aria-hidden='true']");
                setHeroSlide(hiddenSlides);
            }
        },
        adaptiveHeight: true,
        cellAlign: 'left',
        draggable: true,
        pageDots: false,
        prevNextButtons: false,
        wrapAround: true,
        setGallerySize: true
    });

    const pageDotsGroup = document.querySelector('.flickity-page-dots');
    const pageDotButtons = document.querySelectorAll('.page-dots');
    const pageDotButtonsArray = Array.prototype.slice.call(pageDotButtons);
    const flickitySlider = document.querySelector('.flickity-slider');
    let previousButton = document.querySelector('.carousel--prev');
    let nextButton = document.querySelector('.carousel--next');

    // flickity select method used to update carousel cell classes, aria-hidden and tabindex
    // https://flickity.metafizzy.co/api.html#select
    heroSlider.on( 'select', () => {
        let previousSelectedSlide = flickitySlider.querySelector('.is-selected');
        let focusEls = getKeyboardFocusableElements(previousSelectedSlide);
        previousSelectedSlide.setAttribute('tabindex', '-1');
        focusEls.forEach((focusEl => {
            focusEl.setAttribute('tabindex', '0')
        }));
        let hiddenSlides = document.querySelectorAll(".featured-slide[aria-hidden='true']");
        setHeroSlide(hiddenSlides);
        if (pageDotsGroup && pageDotButtons) {
            selectCarouselDots (pageDotsGroup, pageDotButtons, heroSlider);
        }
    });

    if (previousButton) {
        previousButton.addEventListener('click', function () {
            heroSlider.previous();
        });
    }
    if (nextButton) {
        nextButton.addEventListener('click', function () {
            heroSlider.next();
        });
    }

    if (pageDotsGroup && pageDotButtons) {
        pageDotButtons.forEach((pageDotButton) => {
            pageDotButton.addEventListener('click', () => {
                if (!pageDotButton.classList.contains('is-selected')) {
                    selectCarouselDots (pageDotsGroup, pageDotButtons, heroSlider);
                    let index = pageDotButtonsArray.indexOf( event.target );
                    heroSlider.select( index );
                }
            });
        });
    }
});

